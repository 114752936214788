import axios from '@axios'
import { computed } from '@vue/composition-api'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { sessionSemester } from '@/helpers/instanceNamingConvention'
import store from '@/store'

export default function useFafsaReportDetails() {
  // Use toast
  const toast = useToast()

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  const paymentTableColumns = [
    { key: 'type', label: 'Payment Methods', class: 'w-50' },
    { key: 'master_payment_type', label: 'Master payment type', class: 'w-50' },
  ]
  const chargeTableColumns = [
    { key: 'hide', label: 'Hide', class: 'w-10 text-break' },
    { key: 'type', label: 'Charges Types', class: 'w-40 text-break' },
    { key: 'master_payment_type', label: 'Master charge type', class: 'w-50' },
  ]
  const fafsaReportTableColumns = [
    { key: 'pay_date', label: 'Date' },
    { key: 'master_payment_type', label: 'Type' },
    { key: 'master_payment_method_type', label: 'Payment Method' },
    { key: 'sessions', label: `${sessionSemester}` },
    { key: 'check_number', label: 'Ref.#' },
    { key: 'charges', label: 'Charges' },
    { key: 'payments', label: 'Payments & Credits' },
    { key: 'remaining_total', label: 'Balance' },
  ]

  const fetchReport = ID => axios
    .get(`/auth/fafsa/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Tuition Card item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postReport = queryParams => axios
    .post('/auth/fafsa', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Tuition Card item has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const updateReport = (ID, queryParams) => axios
    .put(`/auth/fafsa/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Tuition Card item was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Tuition Card',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchStudentsList = queryParams => axios
    .get('/auth/users/', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Students list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchTransactionsList = queryParams => axios
    .post('/auth/filter-transactions', queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Transactions list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportPdf = queryParams => axios
    .post('/auth/fafsa/pdf', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting pdf',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const downloadBalanceReport = queryParams => axios
    .post('/auth/fafsa/balance-report', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting report balance',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchFafsaGeneratedList = queryParams => axios
    .get('/auth/fafsa/generate', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error generating report',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchMasterPaymentTypes = () => axios
    .get('/auth/fafsa-type')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Master Payment Types',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postMasterPaymentType = queryParams => axios
    .post('/auth/fafsa-type', queryParams)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating new Master Payment Type',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const deleteMasterPaymentType = ID => axios
    .delete(`/auth/fafsa-type/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error deleting Master Payment Type',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    paymentTableColumns,
    chargeTableColumns,
    fafsaReportTableColumns,
    fetchReport,
    postReport,
    updateReport,
    fetchProgram,
    fetchStudentsList,
    fetchTransactionsList,
    fetchFafsaGeneratedList,
    exportPdf,
    downloadBalanceReport,
    fetchMasterPaymentTypes,
    postMasterPaymentType,
    deleteMasterPaymentType,
    programId,
  }
}
